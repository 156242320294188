<template>
  <div class="view-container">
    <div class="view">
      <div class="view-heading">
        <div class="view-title">
          {{ model.title }}
        </div>
      </div>
      <div class="view-body">
        <b>
          <label>{{ $t('system.description') }}:</label>
        </b>
        <div v-html="model.description" />
      </div>
      <div class="view-body col-4">
        <b>
          <label>{{ $t('system.files') }}:</label>
        </b>
        <FilesList
            :download-progress="fileDownloadProgress"
            :files="files"
            @download="downloadFile"
            download
        />
      </div>
      <div class="box-button">
        <el-button
            @click="$router.push('/knowledge_base/' + model.id + '/edit')"
            type="info"
            v-permission="['knowledge-base-update']"
        >
          {{ $t('system.edit') }}
        </el-button>
        <el-button
            @click="remove"
            type="danger"
            v-permission="['knowledge-base-delete']"
        >
          {{ $t('system.delete') }}
        </el-button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import swal from 'sweetalert2';
import {mapGetters} from 'vuex';
import notification from '../../notification/notify';
import FilesList from '../../components/FilesList/FilesList.vue';
import {downloadFile} from '../../helpers/files';

export default {
  name: 'KnowledgeBaseShow',
  components: {
    FilesList,
  },
  data() {
    return {
      model: {},
      attachments: [],
      resource: 'knowledge_base',
      redirect: '/knowledge_base',
      fileDownloadProgress: {
        id: null,
        progress: 0,
      },
      files: [],
      dialogVisible: false,
      dialogImageUrl: String,
      isImg: false,
      fileName: '',
      rules: {
        title: [
          {required: true, message: 'Title is required', trigger: 'change'},
        ],
        description: [
          {required: true, message: 'Description is required', trigger: 'change'},
        ],
        files: [
          {required: true, message: 'File is required', trigger: 'change'},
        ],
      },
    };
  },
  beforeMount() {
    this.fetchData();
  },
  watch: {
    '$route': 'fetchData',
  },
  computed: {
    ...mapGetters(['auth/token']),
    token() {
      return this._self['auth/token'];
    },
  },
  methods: {
    updateCheckedPermissions(e) {
      this.checkedPermissions = e;
    },
    async downloadFile(item) {
      this.fileDownloadProgress.id = item.id;
      //TODO change endpoint
      const file = await axios.get(`/knowledge_base/attachment/${item.id}`, {
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          this.fileDownloadProgress.progress = Math.round((progressEvent.loaded * 100) / item.filesize);
        },
      });
      setTimeout(() => {
        this.fileDownloadProgress = {
          id: null,
          progress: 0,
        };
        downloadFile(file, item.filename);
      }, 700);
    },
    remove() {
      var vm = this;
      // const swalWithBootstrapButtons = swal.mixin({
      //   confirmButtonClass: 'btn btn-success',
      //   cancelButtonClass: 'btn btn-danger',
      //   buttonsStyling: false
      // });
      swal.fire({
        title: vm.$t('system.if_you_sure'),
        text: vm.$t('system.fileremovaldescription'),
        type: 'warning',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: vm.$t('system.yes'),
        cancelButtonText: vm.$t('system.no'),
      }).then((result) => {
        if (result.value) {
          axios.delete(`${this.resource}/${this.$route.params.knowledgeBaseId}`)
              .then(function (response) {
                notification.notify(
                    vm.$t('notify.success'),
                    vm.$t('system.deleted'),
                    'success');
                if (response.data.deleted) {
                  vm.$router.push(vm.redirect);
                }
              })
              .catch(function (error) {
                notification.notify(
                    vm.$t('notify.error'),
                    vm.$t(error.response.data.error),
                    'error');
              });
          // notification.notify(
          //   vm.$t('notify.success'),
          //   'Your file has been deleted.',
          //   'success');
        } else if (
            // Read more about handling dismissals
            result.dismiss === swal.DismissReason.cancel
        ) {
          // swalWithBootstrapButtons(
          //   'Cancelled',
          //   'Your imaginary file is safe :)',
          //   'error'
          // )
        }
      });
    },
    async fetchData() {
      try {
        const {data} = await axios.get(`${this.resource}/${this.$route.params.knowledgeBaseId}`);
        this.model = data.model;
        this.files = data.attachments;
      } catch (error) {
        notification.notify(
            this.$t('notify.error'),
            this.$t('system.can_not_load'),
            'error');
      }
    },
    removeFile(file) {
      let that = this;
      swal.fire({
        title: that.$t('system.if_you_sure'),
        text: that.$t('system.fileremovaldescription'),
        type: 'warning',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: that.$t('system.yes'),
        cancelButtonText: that.$t('system.no'),
      }).then(function () {
        axios.delete(`storage/${file.id}`)
            .then(function (response) {
              that.fetchData();
              notification.notify(
                  that.$t('notify.success'),
                  that.$t('system.filewasdeleted'),
                  'success');
            })
            .catch(function (error) {
              notification.notify(
                  that.$t('notify.error'),
                  that.$t(error.response.data.error),
                  'error');
            });
      }).catch(swal.noop);
    },
  },
};
</script>
